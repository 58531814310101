import React, { useState, useEffect } from "react";
import "./timer.css";
import logo from "../../images/MATC.jpg";
import image_Bg from "../../images/img_bg_1.jpg";

function Timar() {
  // Initial state for countdown
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // Countdown logic
  useEffect(() => {
    const targetDate = new Date("2024-12-31T23:59:59"); // Replace with your desired target date

    const intervalId = setInterval(() => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        clearInterval(intervalId);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  return (
    <header
      id="fh5co-header"
      className="Timar fh5co-cover"
      role="banner"
      style={{
        backgroundImage: `url(${image_Bg})`,
        color: "#fff",
        paddingTop: "50px",
      }}
      data-stellar-background-ratio="0.5"
    >
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center">
            <div className="display-t">
              <div
                className="display-tc animate-box"
                data-animate-effect="fadeIn"
              >
                <img
                  style={{ width: "200px", height: "200px", margin: "auto" }}
                  src={logo}
                  alt="image"
                />
                <h1 className="text-white my-3">We Are Coming Very Soon!</h1>

                <div className="simply-countdown simply-countdown-one">
                  <div className="simply-section simply-days-section">
                    <div>
                      <span className="simply-amount">{timeLeft.days}</span>
                      <span className="simply-word">days</span>
                    </div>
                  </div>
                  <div className="simply-section simply-hours-section">
                    <div>
                      <span className="simply-amount">{timeLeft.hours}</span>
                      <span className="simply-word">hours</span>
                    </div>
                  </div>
                  <div className="simply-section simply-minutes-section">
                    <div>
                      <span className="simply-amount">{timeLeft.minutes}</span>
                      <span className="simply-word">minutes</span>
                    </div>
                  </div>
                  <div className="simply-section simply-seconds-section">
                    <div>
                      <span className="simply-amount">{timeLeft.seconds}</span>
                      <span className="simply-word">seconds</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Timar;
