import { useEffect, useState } from "react";
import "./App.css";
import E_Trainings_Formateur from "./components/E-Trainings/E_Trainings_Formateur";
import E_Trainings_Verivication from "./components/E-Trainings/E_Trainings_Verivication";
import E_Trainings_Verivication_Detail from "./components/E-Trainings/E_Trainings_Verivication_Detail";
import { Formation_Dinitiation } from "./components/E-Trainings/Formation_Dinitiation";
import { Formation_Dinitiation_Course } from "./components/E-Trainings/Formation_Dinitiation_Course";
import { Navbar } from "./components/Navbar";
import CourseSingle from "./pages/CourseSingle";
import { E_Trainings } from "./pages/E_Trainings";
import { Home } from "./pages/Home";
import "./styles/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import Dashboard_Login from "./components/dashboard_navbar/Dashboard_Login";
import Formation_Participant from "./components/E-Trainings/Formation_Participant";
import Formation_Participant_Course from "./components/E-Trainings/Formation_Participant_Course";
import Formation_Participant_Course_Details from "./components/E-Trainings/Formation_Participant_Course_Details";
import E_Trainings_Checkout from "./components/E-Trainings/E_Trainings_Checkout";
import E_Trainings_Course_price from "./components/E-Trainings/E_Trainings_Course_price";
import E_Trainings_Course_groups from "./components/E-Trainings/E_Trainings_Course_groups";

function App() {
  const [auth, setAuth] = useState(false);

  useEffect(() => {}, [auth]);

  return (
    <div className="App">
      <Navbar />
      <Routes>
        {/* home */}
        <Route path="/" element={<Home />} />
        {/* E-Trainings */}
        <Route path="/E-Trainings" element={<E_Trainings />} />
        {/* E-Trainings > Verivication */}
        <Route path="/Verivication" element={<E_Trainings_Verivication />} />
        {/* E-Trainings > Verivication > Verivication-Detail */}
        <Route
          path="/Verivication-Detail"
          element={<E_Trainings_Verivication_Detail />}
        />
        {/* E-Trainings > formation-dinitiation */}
        <Route
          path="/formation-dinitiation"
          element={<Formation_Dinitiation />}
        />
        {/* E-Trainings > formation-dinitiation > ourse */}
        <Route
          path="/formation-dinitiation-ourse"
          element={<Formation_Dinitiation_Course />}
        />
        {/* E-Trainings > formation-dinitiation > ourse > course-single */}
        {/* E-Trainings > ourse > course-single */}
        <Route path="/course-single/:id" element={<CourseSingle />} />
        {/* E-Trainings > ourse > course-single > Course price */}
        <Route path="/Course-price" element={<E_Trainings_Course_price />} />
        {/* E-Trainings > ourse > course-single > Course price > Checkout */}
        <Route path="/Course-Checkout" element={<E_Trainings_Checkout />} />
        {/* E-Trainings > ourse > course-single > Course price > Checkout > Course groups */}
        <Route path="/Course-groups" element={<E_Trainings_Course_groups />} />
        {/* E-Trainings > Formateur */}
        <Route
          path="/Formateur"
          element={
            auth ? (
              <E_Trainings_Formateur setAuth={setAuth} />
            ) : (
              <Dashboard_Login setAuth={setAuth} />
            )
          }
        />
        {/* E-Trainings > Participant */}
        <Route path="/Participant" element={<Formation_Participant />} />
        {/* E-Trainings > Participant > Course */}
        <Route
          path="/Formation-Participant-Course"
          element={<Formation_Participant_Course />}
        />
        {/* E-Trainings > Participant > Course > Course Details */}
        <Route
          path="/Formation-Participant-Course-Details"
          element={<Formation_Participant_Course_Details />}
        />
      </Routes>
    </div>
  );
}

export default App;
