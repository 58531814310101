// app/store.js
import { configureStore } from "@reduxjs/toolkit";
import cours_payant_Slice from "./Slices/cours_payant_Slice";

const store = configureStore({
  reducer: {
    price: cours_payant_Slice,
  },
});

export default store;
